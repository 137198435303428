<template>
  <dash-page-new
      :title="newsletter.name || ''"
      :root="$t('mailer.automations.title')"
      :root-location="businessDashLink('fine_mailer/campaigns')"
      :subtitle="$t('Preview')"
      background-color="var(--wsLIGHTCARD)"
      no-body-padding
  >
    <template #header_action>

      <ft-select
          :items="headerActionsSelect"
      >
        <ws-button
            :color="wsACCENT"
            :left-icon-color="statusColor"
            :click-stop="false"
            icon="mdi-menu-down"
            left-icon="mdi-circle-medium"
            :label="newsletter.status ? `mailer.campaigns.status.${newsletter.status}` : 'Status'"
            outlined
        />
      </ft-select>


    </template>
    <template #default>
      <div class="d-flex flex-column fill-height">

        <!-- Header-->
        <v-sheet class="mt-5" color="transparent">

          <div class="d-flex flex-row justify-space-between "
               :class="[
                   { 'px-10' : !SM } ,
                   { 'px-4'  : SM }
               ]"
          >

            <v-sheet
                v-for="(item,i) in statisticsSelect" :key="i"
                style="border: 1px solid var(--wsBACKGROUND);"
                class="wsRoundedSemi flex-grow-1 pa-4"
                :class="[{'ml-3' : i !== 0 }]"
            >
              <v-icon :color="wsACCENT">{{ item.icon }}</v-icon>
              <h5 style="font-size: 12px" class="py-1 wsACCENT">{{ item.title }}</h5>
              <h3 style="font-size: 19px" class="wsACCENT">
                {{ item.text }}
                <span class="font-weight-regular" v-if="item.text_regular">({{ item.text_regular }})</span>
              </h3>
            </v-sheet>

          </div>

          <ws-navigation-header
              :items="navigationSelect"
              v-model="navigation"
              class="mt-4"
              padding="2px"
          />
        </v-sheet>

        <!-- Content -->
        <v-sheet
            :class="[{ 'px-10' : !SM },{ 'px-4'  : SM }]"
            class="flex-grow-1 fill-height"
        >
          <ws-navigation
              :items="navigationSelect"
              v-model="navigation"
              hide-header
          >
            <template #item.receivers>
              <ws-data-table
                  :items="receivers"
                  :headers="headers"
                  class="mt-2"
              >
                <template #item.name="{item}">
                  <h5 class="wsACCENT">
                    <v-icon :color="getReceiverColor(item.status)"  >mdi-circle-medium </v-icon>
                    {{ item.name }}
                  </h5>
                </template>

                <template #item.date="{item}">
                  <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date) }}</h5>
                </template>

                <template #item.status="{item}">
                 <ws-chip min-width="200" class="mx-auto" outlined :color="getReceiverColor(item.status)" :text="$t(`mailer.receivers.status.${item.status}`)"></ws-chip>
                </template>

              </ws-data-table>
            </template>

            <template #item.design>
              <email-viewer
                  :uuid="newsletter.newsletter_design_id"
              />
            </template>

            <template #item.info>
              <h4 v-for="(value,key) in newsletter" class="wsDARKER" :key="key">
                {{ key }} : <span class="font-weight-regular"> {{ value }}</span>
              </h4>
            </template>

          </ws-navigation>
        </v-sheet>

      </div>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import finemailer from "@/mixins/finemailer";
import EmailViewer from "@/components/pages/businessDashboard/fineMailer/emailConstructor/viewer/EmailViewer.vue";
export default {
  name: "mailerLetters",
  mixins : [finemailer],
  components : {
    EmailViewer
  },
  props : {
    uuid : {
      type : String,
    }
  },
  data() {
    return {
      navigation : 'receivers',

      newsletter : {},
      emailDesign : {},
      designReady : false,

      totalReceivers : 0,
      totalDelivered : 0,
      totalOpened : 0,
      totalLinksClicks : 0,
      totalUnsubscribed : 0,
      totalBounced : 0,

      receivers : [
          {
            name : "Золотий півник",
            email : "golden-cock@big.dick",
            date : 2134234234,
            status : 'delivered',
            links : 3,
          },
        {
          name : "Jeffrey Who Bezos",
          email : "jeffrey@bezos.com",
          date : 2134234234,
          status : 'bounced',
          links : 3,
        },
        {
          name : "Elon Margery Tusk",
          email : "elon@tusk.com",
          date : 2134234234,
          status : 'unsubscribed',
          links : 3,
        },
        {
          name : "Gordon Gecko",
          email : "gorder-the-gecko@amazon.com",
          date : 2134234234,
          status : 'sending',
          links : 3,
        },

      ],

    }
  },
  computed : {
    statusColor() {
        switch(this.newsletter.status) {
          case 'sent' : return this.wsSUCCESS
          case 'draft' : return this.wsDARKLIGHT
          default : return this.wsACCENT
        }
    },
    headers() {
      return [
        { text : 'Contact'  , value : 'name'   },
        { text : 'Email'    , value : 'email'   },
        { text : 'Date'     , value : 'date'    },
        { text : 'Clicks'   , value : 'links'   },
        { text : 'Status'   , value : 'status' ,width : '10px' },
      ]
    },

    isAutomation() {
      return this.newsletter.is_automation
    },
    statisticsSelect() {
      return [
        { icon : 'mdi-account-multiple',
          title : this.$t('mailer.stats.total'),
          value : 'total',
          text : this.totalReceivers,
        },
        // check-circle-outline
        { icon : 'mdi-email-check-outline',
          title : this.$t('mailer.stats.delivered'),
          value : 'delivered',
          text : this.totalDeliveredPercent,
          text_regular : this.totalDelivered,
        },
        { icon : 'mdi-email-open-outline',
          title : this.$t('mailer.stats.opened'),
          value : 'opened',
          text : this.totalOpenedPercent,
          text_regular : this.totalOpened,
        },
        { icon : 'mdi-open-in-new',
          title : this.$t('mailer.stats.links_opened'),
          value : 'clicks',
          text : this.totalLinksClicksPercent,
          text_regular : this.totalLinksClicks,
        },
        { icon : 'mdi-close-circle-outline',
          title : this.$t('mailer.stats.unsubscribed'),
          value : 'unsubscribe',
          text : this.totalUnsubscribedPercent,
          text_regular : this.totalUnsubscribed,
        },
        { icon : 'mdi-email-remove-outline',
          title : this.$t('mailer.stats.bounced'),
          value : 'sending',
          text : this.totalBouncedPercent,
          text_regular : this.totalBounced,
        }
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('mailer.campaigns.receivers') , value : 'receivers' },
        { text : this.$t('mailer.preview_list') , value : 'design' },
        { text : this.$t('mailer.newsletter.info') , value : 'info' }
      ]
    },
    headerActionsSelect() {
      return [
        { text : this.$t('PauseNewsletter') , action : this.pauseNewsletter },
        { text : this.$t('PauseNewsletter') , action : this.pauseNewsletter }
      ]
    },

    // Statistics percents counting

    totalDeliveredPercent() {
      if (!this.totalDelivered || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalDelivered/this.totalReceivers)*100) + '%'
    },
    totalOpenedPercent() {
      if (!this.totalOpened || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalOpened/this.totalReceivers)*100) + '%'
    },
    totalLinksClicksPercent() {
      if (!this.totalLinksClicks || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalLinksClicks/this.totalReceivers)*100) + '%'
    },
    totalUnsubscribedPercent() {
      if (!this.totalUnsubscribed || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalUnsubscribed/this.totalReceivers)*100) + '%'
    },
    totalBouncedPercent() {
      if (!this.totalBounced || !this.totalReceivers) {
        return '0%'
      }
      return parseInt((this.totalBounced/this.totalReceivers)*100) + '%'
    },
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'START_NEWSLETTER',
    ]),

    pauseNewsletter() {
      this.notify('pauseNewsletter')
    },
    //Techincal

    getReceiverColor(status) {
      switch(status) {
        case 'delivered': return this.wsSUCCESS
        case 'bounced' : return this.wsATTENTION
        case 'unsubscribed' : return this.wsWARNING
        default : return this.wsDARKLIGHT
      }
    },
    // Init Data functions
    async initPage() {
      let result  = await this.GET_NEWSLETTER(this.uuid)
      if (!result) {
        return this.ERROR()
      }
      this.newsletter = result

      this.totalReceivers    = result.total_receivers     || 124
      this.totalDelivered    = result.total_delivered     || 92
      this.totalOpened       = result.total_opened        || 60
      this.totalLinksClicks  = result.total_links_clicks  || 46
      this.totalUnsubscribed = result.total_unsubscribed  || 6
      this.totalBounced      = result.total_bounced       || 12
    }
  },
  mounted() {
    this.initPage()
  }


}
</script>

<style scoped>

</style>