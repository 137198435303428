var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-page-new',{attrs:{"title":_vm.newsletter.name || '',"root":_vm.$t('mailer.automations.title'),"root-location":_vm.businessDashLink('fine_mailer/campaigns'),"subtitle":_vm.$t('Preview'),"background-color":"var(--wsLIGHTCARD)","no-body-padding":""},scopedSlots:_vm._u([{key:"header_action",fn:function(){return [_c('ft-select',{attrs:{"items":_vm.headerActionsSelect}},[_c('ws-button',{attrs:{"color":_vm.wsACCENT,"left-icon-color":_vm.statusColor,"click-stop":false,"icon":"mdi-menu-down","left-icon":"mdi-circle-medium","label":_vm.newsletter.status ? ("mailer.campaigns.status." + (_vm.newsletter.status)) : 'Status',"outlined":""}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-sheet',{staticClass:"mt-5",attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between ",class:[
                 { 'px-10' : !_vm.SM } ,
                 { 'px-4'  : _vm.SM }
             ]},_vm._l((_vm.statisticsSelect),function(item,i){return _c('v-sheet',{key:i,staticClass:"wsRoundedSemi flex-grow-1 pa-4",class:[{'ml-3' : i !== 0 }],staticStyle:{"border":"1px solid var(--wsBACKGROUND)"}},[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v(_vm._s(item.icon))]),_c('h5',{staticClass:"py-1 wsACCENT",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.title))]),_c('h3',{staticClass:"wsACCENT",staticStyle:{"font-size":"19px"}},[_vm._v(" "+_vm._s(item.text)+" "),(item.text_regular)?_c('span',{staticClass:"font-weight-regular"},[_vm._v("("+_vm._s(item.text_regular)+")")]):_vm._e()])],1)}),1),_c('ws-navigation-header',{staticClass:"mt-4",attrs:{"items":_vm.navigationSelect,"padding":"2px"},model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}})],1),_c('v-sheet',{staticClass:"flex-grow-1 fill-height",class:[{ 'px-10' : !_vm.SM },{ 'px-4'  : _vm.SM }]},[_c('ws-navigation',{attrs:{"items":_vm.navigationSelect,"hide-header":""},scopedSlots:_vm._u([{key:"item.receivers",fn:function(){return [_c('ws-data-table',{staticClass:"mt-2",attrs:{"items":_vm.receivers,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
             var item = ref.item;
return [_c('h5',{staticClass:"wsACCENT"},[_c('v-icon',{attrs:{"color":_vm.getReceiverColor(item.status)}},[_vm._v("mdi-circle-medium ")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.date",fn:function(ref){
             var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date)))])]}},{key:"item.status",fn:function(ref){
             var item = ref.item;
return [_c('ws-chip',{staticClass:"mx-auto",attrs:{"min-width":"200","outlined":"","color":_vm.getReceiverColor(item.status),"text":_vm.$t(("mailer.receivers.status." + (item.status)))}})]}}])})]},proxy:true},{key:"item.design",fn:function(){return [_c('email-viewer',{attrs:{"uuid":_vm.newsletter.newsletter_design_id}})]},proxy:true},{key:"item.info",fn:function(){return _vm._l((_vm.newsletter),function(value,key){return _c('h4',{key:key,staticClass:"wsDARKER"},[_vm._v(" "+_vm._s(key)+" : "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(value))])])})},proxy:true}]),model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }